import "./index.scss";
import Swiper, { Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import "./index.scss";

const services_swiper = new Swiper(".services-swiper", {
  slidesPerView: "3",
  spaceBetween: 10,
  speed: 1000,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
    renderBullet: (index, className) => {
      return `<span class="${className}"></span>`;
    },
  },

  navigation: {
    nextEl: ".services-swiper-btn-next",
    prevEl: ".services-swiper-btn-prev",
  },

  breakpoints: {
    800: {
      slidesPerView: "3",
    },
    500: {
      slidesPerView: "2",
    },
    300: {
      slidesPerView: "1",
    },
  },
});
