// script interface
import "../components/common_componentc/normalize/index";
import "../components/interface/grid/index";
import "../components/interface/form/index";
import "../components/interface/button/index";
import "../components/interface/card/card";

// script common elements

import "../components/module/modal_script/index";
import "../components/module/swiper/index";
import "../components/module/form_action/index";
import "../../source/lib/custom_mask/index";

// script pages
import "../components/common_componentc/header/index";
import "../components/common_componentc/footer/index";
import "../components/common_componentc/contact-us/index";
import "../components/common_componentc/projects/index";
import "../components/common_componentc/blog/index";
import "../components/common_componentc/top_banner/index";

import "../components/pages/index/index";
