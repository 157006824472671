import Swiper, { Autoplay, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/swiper-bundle.css";
import "./index.scss";

Swiper.use([Autoplay, Navigation, Pagination, Scrollbar]);

const banner_swiper = new Swiper(".banner_swiper", {
  slidesPerView: "1",
  loop: true,

  navigation: {
    nextEl: ".top-slider-btn-next",
    prevEl: ".top-slider-btn-prev",
  },

  pagination: {
    el: ".banner_swiper_pagination",
    type: "fraction",
    renderFraction: function (currentClass, totalClass) {
      return `<div class="pagination_wrapper"> 
                <span class="currentZero">0</span><span class="${currentClass}"></span> 
                <span class="totalZero">/0</span><span class="${totalClass}"></span> 
            </div>`;
    },
  },
});

const projects_swiper = new Swiper(".projects_swiper", {
  slidesPerView: "4",
  spaceBetween: 10,
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
    renderBullet: (index, className) => {
      return `<span class="${className}"></span>`;
    },
    // renderFraction: function (currentClass, totalClass) {
    //     return `<div class="pagination_wrapper">
    //         <span class="currentZero">0</span><span class="${currentClass}"></span>
    //         <span class="totalZero">/0</span><span class="${totalClass}"></span>
    //     </div>`
    // }
  },

  navigation: {
    nextEl: ".projects_swiper-btn-next",
    prevEl: ".projects_swiper-btn-prev",
  },

  breakpoints: {
    1000: {
      slidesPerView: "4",
    },
    800: {
      slidesPerView: "3",
    },
    500: {
      slidesPerView: "2",
    },
    300: {
      slidesPerView: "1",
    },
  },
});
