import './index.scss'

const hamburger = document.querySelector('#hamburger')
const header = document.querySelector('.header')

hamburger.addEventListener('click', () => header.classList.toggle('active'))


const header_nav__item = document.querySelector('.header_nav__item')
const width = window.innerWidth

if (width < 1000) {
    header_nav__item.addEventListener('click', () => header_nav__item.classList.toggle('active'))
} 

const select_lang = document.querySelectorAll('.select_lang')

select_lang.forEach(select => {
    select.addEventListener('change', () => {
        window.location = `/set_lang/${select.value}`
    })
})